import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import {
  FaHome,
  FaUserSecret,
  FaTwitter,
  FaGithub,
  FaHashtag
} from 'react-icons/fa'

const Sidebar = styled.div`
  /* Device = Tablets, iPads (portrait) */
  @media (min-width: 768px) and (max-width: 1024px) {
    width: 20%;
  }

  /* Device = Tablets, iPads (landscape) */
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    width: 20%;
  }

  /* Device = Low resolution Tablets, Mobiles (landscape) */
  @media (max-width: 767px) {
    width: 10%;
    /*
    justify-content: flex-start;
    align-items: center;
    */

    &::before {
      content: '';
      /* margin: 10px; */
    }
  }

  /* display: flex; */
  /* flex-direction: column; */
  width: 30%;
  background-color: #006836;
  color: #dcdcdc;
  /*
  justify-content: flex-start;
  align-items: center;
  */
  text-align: center;
  padding-top: 30px;
`

const Logo = styled.img`
  /* Device = Tablets, iPads (portrait) */
  @media (min-width: 768px) and (max-width: 1024px) {
    width: 80px;
    height: 80px;
  }

  /* Device = Tablets, iPads (landscape) */
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    width: 80px;
    height: 80px;
  }

  /* Device = Low resolution Tablets, Mobiles (landscape) */
  @media (max-width: 767px) {
    width: 20px;
    height: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  border-radius: 50%;
  overflow: hidden;
  width: 152px;
  height: 152px;
`

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`

const StyledHref = styled.a`
  text-decoration: none;
  color: inherit;
`

const MenuWrapper = styled.div`
  @media (max-width: 767px) {
    /* flex-direction: column; */
    div {
      margin: 0;
    }
    span {
      display: none;
    }
  }

  /*
  display: flex;
  flex-direction: row;
  */
  text-align: center;
  div {
    margin: 10px;
  }
`

const SiteTitle = styled.h1`
  /* display: block; */

  /* Device = Tablets, iPads (portrait) */
  @media (min-width: 768px) and (max-width: 1024px) {
    font-size: 16px;
  }

  /* Device = Tablets, iPads (landscape) */
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    font-size: 16px;
  }

  /* Device = Low resolution Tablets, Mobiles (landscape) */
  @media (max-width: 767px) {
    display: none;
  }
`

const NameBoard = styled.h5`
  /* display: flex; */

  /* Device = Tablets, iPads (portrait) */
  @media (min-width: 768px) and (max-width: 1024px) {
    font-size: 24px;
  }

  /* Device = Tablets, iPads (landscape) */
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    font-size: 24px;
  }

  /* Device = Low resolution Tablets, Mobiles (landscape) */
  @media (max-width: 767px) {
    display: none;
  }
`

export default ({ title, authorName }) => (
  <Sidebar>
    <NameBoard>{authorName}</NameBoard>
    <StyledLink to="/">
      <SiteTitle>{title}</SiteTitle>
    </StyledLink>
    <Logo
      src="/images/circular_myIcon_304.png"
      alt={authorName}
    />
    <MenuWrapper>
      <div>
        <p>
          <StyledLink to="/">
            <FaHome /> <span>Home</span>
          </StyledLink>
        </p>
        <p>
          <StyledLink to="/about">
            <FaUserSecret /> <span>About</span>
          </StyledLink>
        </p>
        <p>
          <StyledLink to="/tags">
            <FaHashtag /> <span>Tags</span>
          </StyledLink>
        </p>
        <p>
          <StyledHref
            href="https://github.com/mozx"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaGithub /> <span>Github</span>
          </StyledHref>
        </p>
        <p>
          <StyledHref
            href="https://twitter.com/mozxorg?ref_src=twsrc%5Etfw"
            class="twitter-follow-button"
            data-show-count="false"
            rel="noopener noreferrer"
            target="_blank"
          >
            <FaTwitter /> <span>Twitter</span>
            <script async src="https://platform.twitter.com/widgets.js" charset="utf-8" />
          </StyledHref>
        </p>
      </div>
    </MenuWrapper>
  </Sidebar>
)
